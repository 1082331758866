<template>
  <div>
    <!-- -------------------------Proximas Evaluaciones Profesor------------------------- -->
    <Card
      v-if="user.groups.includes(6)"
      class="dashboard-card"
      :variant_fifth="true"
    >
      <template v-slot:title>
        <b-icon class="card-header-icon" icon="calendar3"></b-icon>
        Próximas
        {{ $getVisibleNames("evaluations2.evaluation", true, "Evaluaciones") }}
      </template>
      <template v-slot:content>
        <template v-if="institution && institution.kl_assessment">
          <div
            v-if="
              next_evaluation_teacher.length != 0 ||
              optionsMatterNextTest.length > 1 ||
              optionsCareerNextTest.length > 1 ||
              optionsSectionNextTest.length > 1 ||
              optionsCampuNextTest.length > 1
            "
            class="container-select mb-2 noprint"
          >
            <div>
              <b-form-select
                id="input-campus"
                class="select-form"
                v-model="select_campus_next_test"
                :options="optionsCampuNextTest"
                @change="getNextEvaluationStudent()"
              ></b-form-select>
            </div>
            <div>
              <b-form-select
                id="input-career"
                class="select-form"
                v-model="select_career_next_test"
                :options="optionsCareerNextTest"
                @change="getNextEvaluationStudent()"
              ></b-form-select>
            </div>
            <div>
              <b-form-select
                id="input-matter"
                class="select-form"
                v-model="select_matter_next_test"
                :options="optionsMatterNextTest"
                @change="getNextEvaluationStudent()"
              ></b-form-select>
            </div>
            <div>
              <b-form-select
                id="input-section"
                class="select-form"
                v-model="select_section_next_test"
                :options="optionsSectionNextTest"
                @change="getNextEvaluationStudent()"
              ></b-form-select>
            </div>
          </div>
          <template
            v-if="user.groups.includes(6) && next_evaluation_teacher.length > 0"
          >
            <b-table-simple
              bordered
              borderless
              small
              hover
              sticky-header="400px"
              class="border-table"
            >
              <b-thead>
                <b-tr>
                  <!-- Columna Nombre Evaluacion (Evaluacion)-->
                  <b-th class="primary-cell-next-test" style="width: 11%">
                    {{
                      $getVisibleNames(
                        "evaluations2.evaluation",
                        false,
                        "Evaluación"
                      )
                    }}
                  </b-th>
                  <!-- Columna Nombre Evaluacion (Test) -->
                  <b-th class="primary-cell-next-test" style="width: 11%">
                    <!-- {{
                        $getVisibleNames(
                          "teaching.evaluationsituation",
                          false,
                          "Situación Evaluativa"
                        )
                      }} -->
                    {{
                      $getVisibleNames(
                        "evaluations2.test",
                        false,
                        "Instrumento"
                      )
                    }}
                  </b-th>
                  <b-th class="primary-cell-next-test" style="width: 9%">
                    {{ $getVisibleNames("mesh.campus", false, "Sede") }}
                  </b-th>
                  <b-th class="primary-cell-next-test" style="width: 11%">
                    {{ $getVisibleNames("mesh.career", false, "Programa") }}
                  </b-th>
                  <b-th class="primary-cell-next-test" style="width: 11%">{{
                    $getVisibleNames(
                      "mesh.egressprofilematter",
                      false,
                      "Asignatura"
                    )
                  }}</b-th>
                  <b-th class="primary-cell-next-test" style="width: 7%">
                    {{ $getVisibleNames("teaching.section", false, "Sección") }}
                  </b-th>
                  <b-th class="primary-cell-next-test" style="width: 5%"
                    >Fecha Inicio</b-th
                  >
                  <b-th class="primary-cell-next-test" style="width: 5%"
                    >Fecha Término</b-th
                  >
                  <b-th class="primary-cell-next-test" style="width: 5%"
                    >Visible para Evaluados</b-th
                  >
                </b-tr>
              </b-thead>

              <b-tbody>
                <!-- {{next_evaluation_teacher}} -->
                <template v-for="test in sortedEvaluations">
                  <b-tr :key="test.id">
                    <!-- Nombre de la Evaluacion (Evaluacion) -->
                    <b-td class="secondary-cell-next-test text-left">
                      <div class="mr-0">
                        {{ test.evaluation_title }}
                      </div>
                    </b-td>
                    <!-- Nombre de la Evaluacion (Test) -->
                    <b-td class="secondary-cell-next-test text-left">
                      <span
                        class="div-href-test"
                        @click="
                          redirectToTestTeacher(test.evaluation_id, test.id)
                        "
                        v-b-tooltip.v-secondary.right.noninteractive="
                          `Ir a la ${$getVisibleNames(
                            'evaluations2.evaluation',
                            false,
                            'Evaluación'
                          )}`
                        "
                      >
                        {{ test.title }}
                      </span>
                    </b-td>
                    <!-- Sede -->
                    <b-td class="secondary-cell-next-test text-left">
                      {{ test.campus }}
                    </b-td>
                    <!-- Carrera -->
                    <b-td class="secondary-cell-next-test text-left">
                      {{ test.career_name }}
                    </b-td>
                    <!-- Asignatura -->
                    <b-td class="secondary-cell-next-test text-left">
                      {{ test.matter_code }} - {{ test.matter_name }}
                    </b-td>
                    <!-- Seccion -->
                    <b-td class="secondary-cell-next-test text-left">
                      {{ test.section_name }}
                    </b-td>
                    <!-- Fecha de Inicio -->
                    <b-td
                      class="secondary-cell-next-test text-left"
                      :class="{
                        'text-blue': isDateInProgress(
                          test.start_time,
                          test.end_time
                        ),
                      }"
                      v-b-tooltip.v-secondary.top.noninteractive="
                        isDateInProgress(test.start_time, test.end_time)
                          ? `${$getVisibleNames(
                              'evaluations2.evaluation',
                              false,
                              'Evaluación'
                            )} en curso`
                          : ''
                      "
                    >
                      <template v-if="test.start_time">
                        {{ test.start_time | FormatToDateTime }}
                      </template>
                      <template v-else> N/A </template>
                    </b-td>
                    <!-- Fecha de Termino -->
                    <b-td
                      class="secondary-cell-next-test text-left"
                      :class="{
                        'text-blue': isDateInProgress(
                          test.start_time,
                          test.end_time
                        ),
                      }"
                      v-b-tooltip.v-secondary.top.noninteractive="
                        isDateInProgress(test.start_time, test.end_time)
                          ? `${$getVisibleNames(
                              'evaluations2.evaluation',
                              false,
                              'Evaluación'
                            )} en curso`
                          : ''
                      "
                    >
                      <template v-if="test.end_time">
                        {{ test.end_time | FormatToDateTime }}
                      </template>
                      <template v-else> N/A </template>
                    </b-td>
                    <b-td class="secondary-cell-next-test text-center">
                      <template v-if="test.visible_by_evaluatees">
                        <span>Si</span>
                      </template>
                      <template v-else>
                        <span>No</span>
                      </template>
                    </b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </template>
          <!-- Si no hay evaluaciones -->
          <template
            v-if="
              user.groups.includes(6) && next_evaluation_teacher.length == 0
            "
          >
            <div class="text-left">
              No hay próximas
              {{
                $getVisibleNames(
                  "evaluations2.evaluation",
                  true,
                  "Evaluaciones"
                )
              }}
            </div>
          </template>
        </template>
        <template v-else>
          <div class="text-left">
            <span>Este módulo no está habilitado para su institución</span>
          </div>
        </template>
      </template>
    </Card>
  </div>
</template>

<script>
// import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "NextTestTeacher",
  components: {
    Card: () => import("@/components/users/Card"),
  },
  data() {
    return {
      first_fetch_next_test: true,
      select_matter_next_test: null,
      select_career_next_test: null,
      select_section_next_test: null,
      select_campus_next_test: null,
      next_evaluation_teacher: [],
      optionsMatterNextTest: [],
      optionsCareerNextTest: [],
      optionsSectionNextTest: [],
      optionsCampuNextTest: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
    }),
    sortedEvaluations() {
      return this.next_evaluation_teacher.slice().sort((a, b) => {
        return new Date(a.start_time) - new Date(b.start_time);
      });
    },
  },
  methods: {
    isDateInProgress(start_time, end_time) {
      const now = new this.$moment();
      const startDate = this.$moment(start_time);
      const endDate = this.$moment(end_time);

      return startDate.isSameOrBefore(now) && endDate.isSameOrAfter(now);
      // estamos verificando si la fecha actual (now) está dentro del rango de tiempo
    },
    optionsMattersNextTest(mattersFromApi) {
      this.optionsMatterNextTest = [
        {
          value: null,
          text: `Filtrar por ${this.$getVisibleNames(
            "mesh.egressprofilematter",
            false,
            "Asignatura"
          )}`,
        },
      ];
      // Crear un Map para almacenar carreras únicas
      const uniqueMatters = new Map();
      // Iterar sobre los datos de la API y llenar el Map
      mattersFromApi.forEach((x) => {
        if (!uniqueMatters.has(x.matter_name)) {
          uniqueMatters.set(x.matter_name, x.matter_id);
        }
      });
      // Convertir el Map en un array de opciones y añadirlo a optionsMatterNextTest
      uniqueMatters.forEach((id, name) => {
        this.optionsMatterNextTest.push({
          value: id,
          text: name,
        });
      });
    },
    optionsCareersNextTest(careersFromApi) {
      this.optionsCareerNextTest = [
        {
          value: null,
          text: `Filtrar por ${this.$getVisibleNames(
            "mesh.career",
            false,
            "Programa"
          )}`,
        },
      ];
      const uniqueCareers = new Map();
      careersFromApi.forEach((x) => {
        if (!uniqueCareers.has(x.career_name)) {
          uniqueCareers.set(x.career_name, x.career_id);
        }
      });
      uniqueCareers.forEach((id, name) => {
        this.optionsCareerNextTest.push({
          value: id,
          text: name,
        });
      });
    },
    optionsSectionsNextTest(sectionsFromApi) {
      this.optionsSectionNextTest = [
        {
          value: null,
          text: `Filtrar por ${this.$getVisibleNames(
            "teaching.section",
            false,
            "Sección"
          )}`,
        },
      ];
      const uniqueSections = new Map();
      sectionsFromApi.forEach((x) => {
        if (!uniqueSections.has(x.section_name)) {
          uniqueSections.set(x.section_name, x.section_id);
        }
      });
      uniqueSections.forEach((id, name) => {
        this.optionsSectionNextTest.push({
          value: id,
          text: name,
        });
      });
    },
    optionsCampusNextTest(campusFromApi) {
      this.optionsCampuNextTest = [
        {
          value: null,
          text: `Filtrar por ${this.$getVisibleNames(
            "mesh.campus",
            false,
            "Sede"
          )}`,
        },
      ];
      const uniqueCampus = new Map();
      campusFromApi.forEach((x) => {
        if (!uniqueCampus.has(x.campus)) {
          uniqueCampus.set(x.campus, x.campus_id);
        }
      });
      uniqueCampus.forEach((id, name) => {
        this.optionsCampuNextTest.push({
          value: id,
          text: name,
        });
      });
    },
    redirectToTestTeacher(evaluation_id, instrument_id = null) {
      this.$router.push({
        name: "NewEvaluationView",
        params: {
          evaluation_id: evaluation_id,
          instrument_id: instrument_id,
        },
      });
    },
    getNextEvaluationStudent() {
      if (
        this.user &&
        this.user.groups.includes(6) &&
        this.institution &&
        this.institution.kl_assessment
      ) {
        let arr = [];

        if (this.select_campus_next_test != null)
          arr.push("campus=" + this.select_campus_next_test);
        if (this.select_career_next_test != null)
          arr.push("career=" + this.select_career_next_test);
        if (this.select_matter_next_test != null)
          arr.push("matter=" + this.select_matter_next_test);
        if (this.select_section_next_test != null)
          arr.push("section=" + this.select_section_next_test);

        let partial_url = "";
        if (arr.length > 0) partial_url = arr.join("&");

        this.$restful
          .Get(
            `/evaluations2/dashboard_teacher_next_tests/?has_dates=${true}&${partial_url}`
          )
          .then((response) => {
            this.next_evaluation_teacher = response;
            if (this.first_fetch_next_test) {
              this.optionsMattersNextTest(response);
              this.optionsCareersNextTest(response);
              this.optionsSectionsNextTest(response);
              this.optionsCampusNextTest(response);
              this.first_fetch_next_test = false;
            }
          });
      }
    },
  },
  mounted() {
    this.getNextEvaluationStudent();
  },
  watch: {
    user() {
      this.getNextEvaluationStudent();
    },
    institution() {
      this.getNextEvaluationStudent();
    },
  },
  created() {
    this.$hasPermissions();
  },
};
</script>

<style scoped>
.text-blue {
  color: blue;
}
.dashboard-card {
  display: block;
  border-radius: 7px;
  margin-top: 5px;
  margin-bottom: 15px;
  box-shadow: 0px 1px 8px -1px #9d9d9d;
  /* box-shadow: 0px 1px 5px 0px #9d9d9d; */
  transition: all 0.5s ease-out;
}
.card-header-icon {
  margin-top: 2px;
  margin-right: 0.1em;
}
.border-table {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.primary-cell-next-test {
  text-align: left;
  vertical-align: middle;
  /* background: transparent !important; */
  background-color: #e8d8d3 !important;
  color: black !important;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.secondary-cell-next-test {
  text-align: center;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
.div-href-test {
  color: #007bff;
}
.div-href-test:hover {
  color: #0057b1 !important;
  text-decoration: underline;
  cursor: pointer;
}
.container-select {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.select-form {
  min-width: 270px;
  font-size: var(--secondary-font-size);
  margin: 3px;
}
@media (max-width: 768px) {
  .container-select div {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .select-form {
    margin: 3px;
  }
}
@media (max-width: 576px) {
  .container-select div {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .select-form {
    margin: 3px;
  }
}
@media print {
  .secondary-cell-next-test {
    background-color: transparent !important;
  }
}
</style>